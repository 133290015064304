import { AppDispatch, RootState } from '../../Util/Redux/Schema';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SentimentIcon, get_cta_color, get_dark_blue } from '../../Util/Helper';
import {
	enter_with_pin,
	fetch_breath_count,
	fetch_current_month_thoughts,
	fetch_gratitudes,
	fetch_sage_thoughts
} from '../../Util/Redux/AsyncMiddleware';
import { useDispatch, useSelector } from 'react-redux';

import { MeteoconsLightningBoltFillAnimated } from '../../assets/svgFiles';
import { makeStyles } from '@mui/styles';
import sageCircle from '../../assets/images/logo/sageCircle.svg';
import sage_vault_header from '../../assets/images/sageLogo.png';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'center',
		height: '100vh',
		alignItems: 'center'
	},
	circle: {
		width: '72px',
		height: '72px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '50%',
		transition: 'transform 0.75s'
	},
	moveRight: {
		transform: 'translateX(50vw)'
	},
	moveLeft: {
		transform: 'translateX(-50vw)'
	},
	incorrect: {
		backgroundColor: 'red',
		transform: 'translateY(75vh)'
	},
	circle1: {
		backgroundColor: '#A1D0F9'
	},
	circle2: {
		backgroundColor: '#A1D0F9'
	},
	circle3: {
		backgroundColor: '#A1D0F9'
	},
	circle4: {
		backgroundColor: '#A1D0F9'
	}
}));
const PasscodeCharacter = (props: {
	// sentiment: string;
	children: React.ReactNode;
}) => {
	return (
		<Paper
			elevation={0}
			sx={{
				width: '72px',
				height: '72px',
				backgroundColor: get_dark_blue(),
				// border: `8px solid ${get_cta_color()}`,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '50%',
				transition: 'transform 0.75s'
			}}
		>
			{props.children}
		</Paper>
	);
};

const LockScreen: React.FC = () => {
	const [passcode, setPasscode] = useState<string>('');
	const [success, setSuccess] = useState<boolean | null>(null);
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		if (sessionStorage.getItem('authorized_state') !== 'authorized') {
			navigate('/');
		}
	}, []);
	const dispatch = useDispatch<AppDispatch>();
	const goToDashboard = async () => {
		setSuccess(true);
		sessionStorage.setItem('authenticated_state', 'authenticated');

		Promise.all([
			dispatch(fetch_current_month_thoughts()),
			dispatch(fetch_sage_thoughts('Month')),
			dispatch(fetch_breath_count('Day')),
			dispatch(fetch_gratitudes('Day'))
		]);
		navigate('/space');
	};
	useEffect(() => {
		const handleKeyPress = (
			event: React.KeyboardEvent<HTMLInputElement>
		) => {
			switch (event.key) {
				case 'Backspace':
					// Remove the last character
					setPasscode((prevText) => prevText.slice(0, -1));
					break;
				case 'Enter':
				case ' ':
					// Do nothing for Enter and Space
					event.preventDefault(); // Optionally prevent default action
					break;
				default:
					// Update the text state with the typed character
					setPasscode((prevText) => prevText + event.key);
					break;
			}
		};

		// Add the event listener to capture key presses
		window.addEventListener('keydown', handleKeyPress);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, []);
	useEffect(() => {
		const attemptLogin = async () => {
			await dispatch(enter_with_pin(passcode));

			if (sessionStorage.getItem('authorized_state') === 'authorized') {
				sessionStorage.setItem(
					'userTimezone',
					Intl.DateTimeFormat().resolvedOptions().timeZone
				);
				if (instruction_type === 'enter0') {
					set_instruction_type('enter1');
					setPasscode('');
				} else {
					await goToDashboard();
				}
			} else {
				setSuccess(false);
				setTimeout(() => {
					// navigate("/space");
					setPasscode('');
					setSuccess(null);
				}, 1000);

				set_instruction_type('incorrect');
			}
		};
		if (passcode.length === 4) {
			attemptLogin();
		}
	}, [passcode]);
	const onOpenModal = () => {
		set_instruction_type('enter0');
	};
	const [instruction_type, set_instruction_type] = useState('type');
	const instruction =
		instruction_type === 'enter0'
			? 'Enter your PIN to change your password'
			: instruction_type === 'enter1'
			  ? 'Change Password: Second, create your PIN'
			  : instruction_type === 'create'
			    ? 'Create your PIN'
			    : instruction_type === 'incorrect'
			      ? 'Incorrect PIN'
			      : 'Type your beta access PIN to Enter (1-2-3-4)';
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			sx={{
				height: '100vh',
				width: '100vw',
				backgroundColor: '#121212',
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
			}}
		>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				width="384px"
			>
				<Grid container item xs direction="column" marginBottom="32px">
					{/* <a href={userPic} target="_blank" rel="noreferrer">
            <Avatar
              sx={{
                width: "80px",
                height: "80px",
                marginBottom: "8px",
              }}
              alt="Uu Profile Picture"
              src={userPic}
            />
          </a> */}

					<Grid
						container
						item
						xs
						marginTop="8px"
						// alignItems="center"
						// direction="column"
					>
						<Grid container item alignItems="center">
							<img
								src={sageCircle}
								height="50px"
								style={{ marginRight: '8px' }}
							/>
							<img
								src={sage_vault_header}
								alt="Vault"
								height="50px"
							/>
						</Grid>
						<Grid container item xs marginTop="24px">
							<Typography
								sx={{
									// color: "#7F7D83",
									color: get_cta_color(),
									fontSize: '16px',
									lineHeight: '22px',
									fontWeight: 600
								}}
							>
								{instruction}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					container
					item
					xs
					alignItems="center"
					justifyContent="space-between"
					sx={{ borderRadius: '50%' }}
					height={72}
				>
					<PasscodeCharacter>
						{passcode.length >= 1 ? (
							success === false ? (
								<MeteoconsLightningBoltFillAnimated />
							) : (
								<SentimentIcon sentiment="unknown" />
							)
						) : (
							<></>
						)}
					</PasscodeCharacter>

					<PasscodeCharacter>
						{passcode.length >= 2 ? (
							success === false ? (
								<MeteoconsLightningBoltFillAnimated />
							) : (
								<SentimentIcon sentiment="harsh" />
							)
						) : (
							<></>
						)}
					</PasscodeCharacter>
					<PasscodeCharacter>
						{passcode.length >= 3 ? (
							success === false ? (
								<MeteoconsLightningBoltFillAnimated />
							) : (
								<SentimentIcon sentiment="sage" size="24px" />
							)
						) : (
							<></>
						)}{' '}
					</PasscodeCharacter>
					<PasscodeCharacter className={classes.circle}>
						{passcode.length >= 4 ? (
							success === false ? (
								<MeteoconsLightningBoltFillAnimated />
							) : (
								<SentimentIcon sentiment="joy" />
							)
						) : (
							<></>
						)}
					</PasscodeCharacter>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default LockScreen;
