import { useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { Home } from './HomePage';
import MainLayout from '../templates/MainTemplate';
import Vault from './EtherPage';
import { headerHeight } from '../../Util/Config';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (sessionStorage.getItem('authorized_state') !== 'authorized') {
			navigate('/');
		}
		if (sessionStorage.getItem('authenticated_state') !== 'authenticated') {
			navigate('/space/gringotts');
		}
	}, [navigate]);
	const [currentSection, setCurrentSection] = useState('Space');
	const containerRef = useRef<React.Ref<Element>>(null);
	const debounce = (func: (...args: any[]) => void, wait: number) => {
		let timeout: NodeJS.Timeout;
		return function (...args: any[]) {
			clearTimeout(timeout);
			timeout = setTimeout(() => func.apply(this, args), wait);
		};
	};
	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const container = containerRef.current;
				const scrollTop = container.scrollTop;
				const containerHeight = container.clientHeight;
				const scrollHeight = container.scrollHeight;

				// Calculate the percentage scrolled
				const scrollPercentage =
					(scrollTop + containerHeight) / scrollHeight;

				// Add a small buffer to prevent flickering
				const buffer = 0.05; // 5% buffer

				// Adjust the logic for detecting scroll position with buffer thresholds
				if (scrollPercentage < 1 / 2 + buffer) {
					setCurrentSection('Space');
				} else if (scrollPercentage < 1 + buffer) {
					if (currentSection !== 'Ether') {
						setCurrentSection('Ether');
					}
				} else {
					setCurrentSection('Space');
				}
			}
		};

		// Debounce to avoid rapid firing of the scroll event
		const debouncedHandleScroll = debounce(handleScroll, 1);

		if (containerRef.current) {
			containerRef.current.addEventListener(
				'scroll',
				debouncedHandleScroll
			);
		}

		return () => {
			if (containerRef.current) {
				containerRef.current.removeEventListener(
					'scroll',
					debouncedHandleScroll
				);
			}
		};
	}, [containerRef, setCurrentSection]);
	return (
		<MainLayout page={currentSection}>
			<Grid
				container
				item
				xs
				width="100%"
				ref={containerRef}
				sx={{
					height: `calc(100vh - ${headerHeight})`,
					overflowY: 'scroll',
					scrollSnapType: 'y mandatory'
				}}
			>
				{['Space', 'Ether'].map((section, idx) => {
					return (
						<Grid
							container
							item
							key={idx}
							alignItems="center"
							justifyContent="center"
							sx={{
								height: `calc(100vh - ${headerHeight})`,
								scrollSnapAlign: 'start',
								padding: '24px 0'
							}}
						>
							{currentSection === section &&
								section === 'Space' && <Home />}
							{currentSection === section &&
								section === 'Ether' && <Vault />}
						</Grid>
					);
				})}
			</Grid>
		</MainLayout>
	);
};

export default Dashboard;
