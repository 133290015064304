import React, { Component, ErrorInfo, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error captured:", error, errorInfo);
  }

  // componentDidUpdate() {
  // 	if (this.state.hasError) {
  // 		// Use window.location.href as a fallback for navigation
  // 		window.location.href = '/error';
  // 	}
  // }

  render() {
    if (this.state.hasError) {
      return <h1>It's not you, it's us</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
