import {
  AddRounded,
  ArrowDropDownRounded,
  AutoAwesomeRounded,
  Circle,
  CloudRounded,
  OpenInFull,
  Star,
  SubdirectoryArrowRightRounded,
} from "@mui/icons-material";
import {
  AppDispatch,
  ReframedThought,
  RootState,
  Thought,
} from "../../../Util/Redux/Schema";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  SentimentIcon,
  formatTime,
  getSentiment,
  getTimeDifference,
  get_cta_color,
  get_sage_thought,
  get_sentiment_color,
} from "../../../Util/Helper";
import {
  create_sage_thought,
  postEmotions,
} from "../../../Util/Redux/AsyncMiddleware";
import { useDispatch, useSelector } from "react-redux";

import SageWorksheet from "../sage_thought/Worksheet";
import SageWorksheetSummary from "../sage_thought/WorksheetSummary";
import { StyledDropdown } from "../../../components/molecules/StyledDropdown";
import { ThoughtEmotionDropdown } from "../emotion/ThoughtEmotionDropdown";
import ThoughtEntryTemplate from "./ThoughtTemplate";
import cloud_add_img from "../../../assets/images/cloudAdd.png";
import { selectThoughtEmotions } from "../../../Util/Redux/Middleware";

const WeatherIcon = () => {
  return <></>;
};
const CloudEmotionSelect = (props: {
  setSelectedEmotions: (emotions: string[]) => void;
  thought: Thought;
  mutable: boolean;
  // boxRef: React.RefObject<HTMLDivElement>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!opened) {
      setOpened(true);
    }
    // setAnimate(true);
  };

  const [emotions, setEmotions] = useState(
    props.thought.user_selected_emotions
  );
  const handleClose = (emotions: string[]) => {
    if (emotions !== props.thought.user_selected_emotions) {
      props.setSelectedEmotions(emotions);
      // Fix Me
      // setAnimate(true);
    }
    setAnchorEl(null);
  };
  const isEmotionSelected =
    !open &&
    (emotions?.length > 0 || !props.mutable) &&
    props.thought.sentiment !== null;
  const [opened, setOpened] = useState(false);
  return (
    <Grid
      container
      item
      xs={1}
      width="48px"
      alignItems="center"
      justifyContent="center"
      sx={{
        "&:hover": {
          backgroundColor: "transparent",
          cursor: isEmotionSelected ? "default" : "pointer",
        },
      }}
    >
      <Grid container item alignItems="center" justifyContent="center">
        {isEmotionSelected ? (
          <SentimentIcon
            sentiment={getSentiment(props.thought.sentiment)}
            size="24px"
            // color={get_cta_color()}
          />
        ) : (
          <Tooltip title="Add emotions" onClick={handleClick}>
            <Grid
              container
              item
              width="52px"
              // marginLeft="-8px"
              // sx={{ backgroundColor: 'red' }}
            >
              {/* <img src={dropdown} width="8px" /> */}
              {/* <img
								src={cloud_add_img}
								style={{
									color: '#EBE9F8'
								}}
							/> */}
              <CloudRounded
                style={{
                  color: "#7c7c7c",
                  opacity: "0.2",
                }}
              />
              {/* {!opened && '+'} */}

              {/* <AddRounded /> */}
            </Grid>
            {/* <img src={} */}
          </Tooltip>
        )}

        <StyledDropdown
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(emotions)}
          sx={{
            maxHeight: "400px",

            width: "400px",
            // marginBottom: "48px",
          }}
        >
          <Grid
            container
            item
            xs
            direction="column"
            width="240px"
            // minHeight="210px"
            // marginBottom="48px"
            sx={{}}
          >
            <ThoughtEmotionDropdown
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              emotions={emotions}
              setEmotions={setEmotions}
              options={props.thought.computed_top_emotions}
              thoughtUuid={props.thought.uuid}
              sentiment={props.thought.sentiment}
            />
          </Grid>
        </StyledDropdown>
      </Grid>
    </Grid>
  );
};
const MemoCloudEmotionSelect = React.memo(CloudEmotionSelect);

export default function ThoughtEntry(props: {
  thought: Thought;
  mutable?: boolean;
  dontShowReframed?: boolean;
  expandable?: boolean;
  showReframe?: boolean;
}) {
  const defaultToTime = !props.mutable;
  const dispatch = useDispatch<AppDispatch>();
  const user_reframed_thought = useSelector((state: RootState) =>
    get_sage_thought(state.sageThoughts, props.thought.uuid)
  ).user_reframed_thought;
  const [showIntrospectButton, setShowIntrospectButton] = useState(
    props.mutable &&
      props.thought.sentiment?.toLowerCase() === "negative" &&
      !user_reframed_thought
  );
  const setSelectedEmotions = async (emotions: string[]) => {
    const response = await postEmotions(props.thought.uuid, emotions);
    if (response !== null) {
      dispatch(
        selectThoughtEmotions(
          props.thought.uuid,
          emotions,
          response.data.sentiment
        )
      );
      setShowIntrospectButton(
        response.data.sentiment?.toLowerCase() === "negative"
      );
    }
  };
  const [expanded, setExpanded] = useState(false);

  if (user_reframed_thought && props.showReframe !== false) {
    return (
      <Grid
        container
        onClick={() => setExpanded(!expanded)}
        item
        xs
        direction="column"
      >
        <ThoughtEntry
          thought={props.thought}
          mutable={props.mutable}
          showReframe={false}
          expandable={false}
        />
        <ReframedThoughtEntry
          defaultToTime={defaultToTime}
          reframed_thought={{
            created_at: props.thought.created_at,
            thought: user_reframed_thought,
            uuid: props.thought.uuid,
          }}
          toggleExpanded={expanded}
          thought={props.thought}
          setToggleExpanded={setExpanded}
        />
      </Grid>
    );
  }
  return (
    <ThoughtEntryTemplate
      icon={
        <MemoCloudEmotionSelect
          thought={props.thought}
          mutable={props.mutable || false}
          setSelectedEmotions={setSelectedEmotions}
        />
      }
      iconArcher={user_reframed_thought ? "source" : undefined}
      header={
        <EmotionTimeTypography
          sentiment={props.thought.sentiment}
          selectedOptions={props.thought.user_selected_emotions || []}
          defaultToTime={defaultToTime || false}
          computed_top_emotions={props.thought.computed_top_emotions}
          created_at={props.thought.created_at}
        />
      }
      thought={props.thought.thought}
      body={
        showIntrospectButton ? (
          <IntrospectButton thought={props.thought} />
        ) : undefined
      }
    />
  );
}

export const ReframedThoughtEntry = (props: {
  reframed_thought: ReframedThought;
  defaultToTime: boolean;
  toggleExpanded: boolean;
  setToggleExpanded: (toggle: boolean) => void;
  thought: Thought;
  // boxRef: React.RefObject<HTMLDivElement>;
}) => {
  const [showSageSummary, setShowSageSummary] = useState(false);
  const sageThought = useSelector(
    (state: RootState) => state.sageThoughts[props.thought.uuid]
  );
  return (
    <ThoughtEntryTemplate
      thought={props.reframed_thought.thought}
      iconArcher="end"
      indentIcon={true}
      icon={
        <Grid
          container
          item
          width="24px"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setShowSageSummary(true)}
        >
          <Tooltip title="View sage summary">
            <SubdirectoryArrowRightRounded
              sx={{
                // marginTop: '16px',
                // marginRight: '12px',
                marginLeft: "-32px",
                color: get_sentiment_color("saged").color,
              }}
            />
            <SentimentIcon sentiment="sage" />
          </Tooltip>
        </Grid>
      }
      header={
        <Grid
          container
          item
          xs
          // alignItems="center"
          width="100%"
          sx={{ cursor: "default" }}
        >
          <Grid container item borderRadius="8px">
            <Grid container item xs>
              <Typography
                sx={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#ADACB0",
                }}
              >
                A more positive way to think about this is...
              </Typography>
              {/* {sageThought?.user_reframed_thought && (
								<Typography
									sx={{
										cursor: 'default',
										fontFamily: 'arial',
										color: '#2c2c2c',
										fontSize: '12px',
										lineHeight: '20px',
										overflow: 'hidden'
									}}
								>
									{sageThought.user_reframed_thought}
								</Typography>
							)} */}
              {showSageSummary && (
                <SageWorksheetSummary
                  open={showSageSummary}
                  setOpen={setShowSageSummary}
                  thought={props.thought}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

const IntrospectButton = (props: { thought: Thought }) => {
  const [openSageWorksheet, setOpenSageWorksheet] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const sage_thoughts = useSelector((state: RootState) => state.sageThoughts);
  const onClick = async () => {
    if (sage_thoughts[props.thought.uuid] === undefined) {
      await dispatch(create_sage_thought(props.thought.uuid));
    }
    setOpenSageWorksheet(true);
  };
  return (
    <Tooltip title="Sage your negative thought">
      <Grid
        container
        item
        // xs
        // height="28px"
        // alignItems="center"
        marginTop="8px"
        // justifyContent="center"
        sx={{
          backgroundColor: "#ECE7F9",
          // backgroundColor: get_cta_color(),
          // border: `4px solid ${get_cta_color()}`,
          // backgroundImage:
          // 	'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
          cursor: "pointer",
        }}
        width="96px"
        padding="4px"
        borderRadius="20px"
      >
        <Button
          fullWidth
          onClick={onClick}
          disableRipple
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              cursor: "pointer",
            },
            backgroundColor: "transparent",
            cursor: "pointer",
            padding: "0",
            borderRadius: "24px",
            textTransform: "none",
          }}
        >
          <Grid container item xs alignItems="center">
            {/* <Star sx={{color: /> */}
            <Grid
              container
              item
              // xs
              sx={{
                backgroundColor: get_cta_color(),
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SentimentIcon sentiment="sage" size="20px" />
            </Grid>
            <Typography
              sx={{
                marginLeft: "10px",
                // color: 'white',
                color: get_cta_color(),
                fontFamily: "arial",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              Sage +
            </Typography>
          </Grid>
        </Button>
        {openSageWorksheet && (
          <SageWorksheet
            open={openSageWorksheet}
            setOpen={setOpenSageWorksheet}
            // setNumberOfBreaths={setNumberOfBreaths}
            thought={props.thought}
          />
        )}

        {/* <BreathingModal
          open={openBreathingOption}
          setOpen={setOpenBreathingOption}
          setCompleted={setOpenBreathingExercise}
          setNumberOfBreaths={setNumberOfBreaths}
        /> */}
        {/* <BreathingExerciseModal
					open={openBreathingExercise}
					setOpenBreathingExercise={setOpenBreathingExercise}
					setCompleted={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/>

				<BreathingFinishedModal
					open={openBreathingFinishedExercise}
					setOpen={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/> */}
        {/* <BreathingExerciseModal
				open={showCompleted}
				setOpen={setNumberOfBreaths}
				numberOfBreaths={numberOfBreaths ?? 0}
			/> */}
      </Grid>
    </Tooltip>
  );
};

const EmotionTimeTypography = (props: {
  computed_top_emotions: string[];
  selectedOptions: string[];
  created_at: string;
  defaultToTime: boolean;
  sentiment: string;
}) => {
  return (
    <Grid
      container
      item
      xs
      alignItems="center"
      width="100%"
      sx={{ cursor: "default" }}
    >
      <TimeTypography
        defaultToTime={props.defaultToTime}
        created_at={props.created_at}
      />
      {props.selectedOptions.length > 0 && (
        <>
          <Circle
            sx={{
              paddingLeft: "4px",
              paddingRight: "4px",
              height: "2px",
              width: "2px",
              color: "#A8A8A8",
            }}
          />
          <AutoAwesomeRounded
            sx={{
              paddingLeft: "4px",
              paddingRight: "4px",
              height: "16px",
              width: "16px",
              color: "#E3E3E7",
            }}
          />
        </>
      )}

      {props.selectedOptions.length > 0 && (
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "18px",
            color: "#ADACB0",
            paddingLeft: "4px",
          }}
        >
          {props.selectedOptions.join(", ")}
        </Typography>
      )}
    </Grid>
  );
};

const TimeTypography = (props: {
  defaultToTime: boolean;
  created_at: string;
}) => {
  const [showFormattedTimeView, setShowFormattedTimeView] = useState(false);
  const nonDefaultTimeFn = props.defaultToTime ? getTimeDifference : formatTime;
  const defaultTimeFn = props.defaultToTime ? formatTime : getTimeDifference;

  return (
    <Typography
      onMouseEnter={() => {
        !props.defaultToTime && setShowFormattedTimeView(true);
      }}
      onMouseLeave={() => {
        !props.defaultToTime && setShowFormattedTimeView(false);
      }}
      sx={{
        // cursor: "default",
        fontFamily: "arial",
        color: "#A8A8A8",
        fontSize: "12px",
        lineHeight: "20px",
        paddingRight: "8px",
      }}
    >
      {(showFormattedTimeView ? nonDefaultTimeFn : defaultTimeFn)(
        props.created_at
      )}
    </Typography>
  );
};
