import { Grid, TextField } from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';
import { useState } from 'react';

export default function Search(props: {
	onEnterSearch: (query: string) => void;
}) {
	const [searchValue, setSearchValue] = useState('');
	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' && searchValue.trim() !== '') {
			props.onEnterSearch(searchValue);
		}
	};
	return (
		<Grid
			container
			item
			style={{
				backgroundColor: '#F8F8F8',
				borderRadius: '16px',
				padding: '4px',
				paddingLeft: '8px',
				height: '36px'
			}}
		>
			<Grid container item sx={{ width: '32px' }} alignItems="center">
				<SearchIcon style={{ color: '#4F4D55' }} />
			</Grid>
			<Grid container item xs sx={{}}>
				<TextField
					value={searchValue}
					placeholder="Search your mind"
					onChange={(e) => setSearchValue(e?.target.value)}
					variant="standard"
					onKeyDown={handleKeyDown}
					style={{
						letterSpacing: '-0.05px',
						width: '100%',
						borderRadius: '4px',
						paddingLeft: '8px',
						outline: 'none'
					}}
					sx={{
						'&:hover': {
							cursor: 'default'
						},
						'& .MuiInputBase-root': {
							'&:hover': {
								borderRadius: '4px',
								cursor: 'default',
								borderBottom: 'none'
							}
						},
						'& .MuiInputBase-input': {
							'&:hover': {
								cursor: 'default'
							},
							fontSize: '14px',
							fontWeight: 450,
							fontFamily: 'arial',
							color: '#4F4D55',
							lineHeight: '18px'
						},
						'& .MuiInput-underline:before': {
							borderBottom: 'none'
						},
						'& .MuiInput-underline:hover:before': {
							borderBottom: 'none !important'
						},
						'& .MuiInput-underline:hover:after': {
							borderBottom: 'none !important'
						},
						'& .MuiInput-underline:after': {
							borderBottom: 'none'
						}
					}}
				/>
			</Grid>
		</Grid>
	);
}
