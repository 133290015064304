import { Grid, Typography } from '@mui/material';
import { SentimentIcon, get_cta_color } from '../../Util/Helper';

import { AirRounded } from '@mui/icons-material';
import BreathCircle from './BreathCircle';
import { RootState } from '../../Util/Redux/Schema';
import { useSelector } from 'react-redux';

export default function Breathwork() {
	const breaths = useSelector(
		(state: RootState) => state.pulseOfPlanet.breath_count
	);
	return (
		<Grid container item xs marginTop="16px" direction="column">
			<Grid container item>
				<SentimentIcon sentiment="air" size="16px" />
				<Typography
					sx={{
						color: '#2f2f2f',
						fontSize: '12px',
						fontFamily: 'arial',
						marginLeft: '8px'
					}}
				>
					Breaths: {breaths || 0}
				</Typography>
			</Grid>

			<BreathCircle
				initText="Take a moment to breathe"
				size="small"
				maxCount={500}
			/>
		</Grid>
	);
}
