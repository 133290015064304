import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import {
  CloudRounded,
  FastRewind,
  PlayArrow,
  TimelineRounded,
} from "@mui/icons-material";
import { get_cta_color, get_sentiment_color } from "../../../Util/Helper";

import { GradientCloudIcon } from "../../../assets/svgFiles";
import MonthDropdown from "../../../components/organisms/MonthDropdown";
import cloud_gradient from "../../../assets/images/svg-files/cloud_gradient.svg";
import React from "react";

const Filters = ({
  sentiment_idx,
  on_change_sentiment_idx,
  descending,
  on_change_descending,
  search_bar,
}: {
  sentiment_idx: number;
  on_change_sentiment_idx: () => void;
  descending: boolean;
  on_change_descending: () => void;
  month_view: string;
  search_bar: React.ReactElement;
}) => {
  return (
    <>
      <FilterRow>
        <Grid container item marginLeft="20px" alignItems="center">
          <Grid
            container
            item
            alignItems="center"
            style={{
              borderRadius: "50%",
              marginLeft: "2px",
              marginRight: "10px",
              marginTop: "-8px",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <Grid container item xs={0.5}>
              <Tooltip
                title={
                  sentiment_idx === 0
                    ? "All thoughts"
                    : sentiment_idx === 1
                      ? "Joyful thoughts"
                      : sentiment_idx === 2
                        ? "Harsh thoughts"
                        : "Unsorted thoughts"
                }
              >
                <IconButton
                  onClick={on_change_sentiment_idx}
                  style={{
                    width: "42px",
                    height: "42px",
                  }}
                >
                  {/* {sentiment_idx === 0 ? (
                  // <TimelineRounded />
                  // <GradientCloudIcon />
                  <img
                    src={cloud_gradient}
                    width="24px"
                    style={{ marginLeft: "-4px" }}
                  />
                ) : ( */}
                  <CloudRounded
                    style={{
                      color:
                        sentiment_idx === 0
                          ? "white"
                          : sentiment_idx === 1
                            ? get_sentiment_color("positive").color
                            : sentiment_idx === 2
                              ? get_sentiment_color("negative").color
                              : "#F3F2F3",
                    }}
                  />
                  {/* )} */}
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <MonthDropdown
							selected_month={month_view}
							on_change_selected_month={on_change_month_view}
							sentiment_idx={sentiment_idx}
						/> */}
            <Grid
              container
              item
              xs={0.5}
              // justifyContent="flex-end"
              alignItems="center"
              //   sx={{ height: "36px", marginLeft: "8px" }}
            >
              <Tooltip title={descending ? "Past" : "Recent"}>
                <IconButton
                  sx={{
                    // width: '24px',
                    // height: "42px",
                    // borderRadius: "42px",
                    textTransform: "none",

                    // backgroundImageColor: 'lightgray',
                    // get_cta_color(),
                    color: "white",
                    "&:hover": {
                      backgroundColor: get_cta_color(0.1),
                    },
                    // padding: '4px'
                  }}
                  onClick={on_change_descending}
                >
                  {descending ? (
                    <FastRewind
                      sx={{
                        width: "28px",
                        height: "28px",
                        color: get_cta_color(),
                      }}
                    />
                  ) : (
                    <PlayArrow
                      sx={{
                        width: "28px",
                        height: "28px",
                        color: get_cta_color(),
                      }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid container item xs>
              {search_bar}
            </Grid>
          </Grid>
        </Grid>
      </FilterRow>
    </>
  );
};

export function FilterRow(props: { children: React.ReactElement }) {
  const { children } = props;
  return (
    <Grid container item alignItems="center" marginTop="0px">
      {children}
    </Grid>
  );
}

export default Filters;
