import { Grid, Typography } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import {
	get_cta_color,
	get_dark_blue,
	get_sentiment_color
} from '../../../Util/Helper';

interface DailyWidgetCardProps {
	title: string;
	icon?: React.ReactNode;
	status?: string[];
	value: string;
	color: string;
	onClick: () => void;
}

export function WidgetGrid(props: {
	marginRight?: boolean;
	marginLeft?: boolean;
	color?: string;
	children: ReactElement<any, string | JSXElementConstructor<any>>;
}) {
	return (
		<Grid
			container
			item
			xs
			direction="column"
			sx={{
				marginLeft: props.marginLeft && '8px',
				marginRight: props.marginRight && '8px',
				background: 'linear-gradient(145deg, #0f2636, #0c1a29)',
				boxShadow:
					'inset 2px 2px 8px #0c1a29, inset -2px -2px 8px #142635',
				borderRadius: '12px',
				padding: '20px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Grid
				container
				item
				xs
				direction="column"
				sx={{
					backgroundColor: props.color || 'white',
					borderRadius: '8px',
					padding: '16px'
				}}
			>
				{props.children}
			</Grid>
		</Grid>
	);
}
const DailyWidgetCard: React.FC<DailyWidgetCardProps> = ({
	title,
	icon,
	status = [],
	onClick,
	value
}) => {
	return (
		<Grid
			container
			item
			// xs={4}
			xs
			alignItems="center"
			padding="4px 0"
			direction="column"
			onClick={onClick}
		>
			<Grid
				container
				item
				xs
				sx={{
					// padding: "8px",
					borderRadius: '40px',
					boxShadow: '0 0 4px rgba(0, 173, 255, 0.6)',
					backgroundColor: 'white'
				}}
			>
				<Grid
					container
					item
					// xs
					direction="row"
					alignItems="center"
					sx={{ height: '100%' }}
				>
					<Grid
						container
						item
						xs
						sx={{
							alignItems: 'center',
							// justifyContent: 'center',
							borderRadius: '50%',
							paddingLeft: '8px'
						}}
					>
						<Grid container item xs={2}>
							{icon}
						</Grid>
						<Typography
							sx={{
								color: get_dark_blue(),
								fontSize: '14px',
								marginLeft: '8px',
								fontWeight: 500
							}}
						>
							{title}
						</Typography>
					</Grid>
					<Grid
						container
						item
						xs={3}
						justifyContent="center"
						alignItems="center"
						sx={{
							height: '100%',
							padding: '4px'
						}}
					>
						<Grid
							container
							item
							xs
							justifyContent="center"
							alignItems="center"
							sx={{
								backgroundColor:
									title === 'Thoughts'
										? '#F2F0EF'
										: get_sentiment_color(title)
												.backgroundColor,
								borderRadius: '8px',
								borderTopRightRadius: '32px',
								borderBottomRightRadius: '32px',
								height: '100%'
								// padding: "4px",
							}}
						>
							<Typography
								sx={{
									color: get_cta_color(),
									fontSize: '14px',
									marginLeft: '8px',
									fontWeight: 800
								}}
							>
								{value}
							</Typography>
						</Grid>
					</Grid>
					{/* <Grid container item xs justifyContent="flex-end">
					<Typography
						sx={{
							color: get_dark_blue(),
							fontSize: '14px',
							marginRight: '4px',
							fontWeight: 500
						}}
					>
						{title}
					</Typography>
				</Grid> */}
				</Grid>
				<Typography sx={{ color: 'white' }}>{status[0]}</Typography>
				<Grid
					container
					item
					xs
					direction="row"
					justifyContent="space-between"
					sx={{ opacity: 0.8, fontSize: '0.9rem' }}
				>
					{status.slice(1, status.length).map((text, index) => (
						<Typography sx={{ color: 'white' }} key={index}>
							{text}
						</Typography>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DailyWidgetCard;
