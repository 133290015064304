import { ArrowDownward, CloudRounded } from "@mui/icons-material";
import { Grid, Modal, Typography } from "@mui/material";
import {
  get_cta_color,
  get_dark_blue,
  get_light_blue,
  get_sentiment_color,
} from "../../Util/Helper";
import { useEffect, useState } from "react";

import MindfulMoments from "../../quest/mindfulness/MindfulMoments";
import { RootState } from "../../Util/Redux/Schema";
import ThoughtsEditor from "../../quest/thought/feed/ThoughtEditor";
import UserPulse from "../molecules/UserPulse";
import sage_demo from "../../assets/sage_demo.mp4";
import { useSelector } from "react-redux";

export const Home = () => {
  const [open, setOpen] = useState(false);
  const current_month_thoughts = useSelector(
    (state: RootState) => state.thoughts.current_month_thoughts
  );
  useEffect(() => {
    const has_visited = sessionStorage.getItem("has_visited");

    if (!has_visited && current_month_thoughts.length === 0) {
      setOpen(true);
      sessionStorage.setItem("has_visited", "true");
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      container
      item
      width="1024px"
      height="768px"
      maxHeight="100%"
      direction="column"
      justifyContent="center"
    >
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          backdropFilter: "blur(5px)",
          zIndex: 3000,
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <Grid
          container
          sx={{
            height: "720px",
            width: "1000px",
            "&:focus": {
              outline: "none",
            },
          }}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflowX: "hidden",
            overflowY: "hidden",
            borderRadius: "16px",
          }}
          direction="column"
        >
          <Grid
            container
            item
            xs
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "16px",
            }}
          >
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                color: get_cta_color(),
                fontSize: "30px",
                fontWeight: 600,
                display: "inline",
              }}
            >
              Thank you beta testers 🫧 Welcome to Sage!
            </Typography>
            <Typography
              sx={{
                alignItems: "center",
                justifyContent: "center",
                color: get_dark_blue(),
                fontSize: "16px",
                fontWeight: 500,
                display: "inline",
                marginBottom: "24px",
              }}
            >
              Please watch this quick tutorial (in full screen) to get started.
            </Typography>
            <video width="900px" controls>
              <source src={sage_demo} type="video/mp4" />
            </video>
          </Grid>
        </Grid>
      </Modal>
      <Grid container item xs width="100%">
        <ThoughtsEditor />
        <Grid
          container
          direction="column"
          item
          xs
          marginLeft="8px"
          borderRadius="16px"
        >
          <Grid
            container
            item
            xs={3}
            sx={{
              marginBottom: "16px",
              padding: "4px",
            }}
            direction="column"
          >
            <UserPulse date_range="Day" />
          </Grid>
          <MindfulMoments />
        </Grid>
      </Grid>
      {/* <Grid
				container
				item
				xs
				position="absolute"
				alignItems="center"
				justifyContent="center"
				right="32px"
				bottom="20px"
				width="32px"
				height="32px"
			>
				<ArrowDownward style={{ color: get_cta_color() }} />
			</Grid> */}
    </Grid>
  );
};

export default Home;
