import { Grid, SvgIconTypeMap, Typography } from '@mui/material';
import { RootState, SageThoughts, Thought } from '../../../Util/Redux/Schema';
import {
	SentimentIcon,
	get_light_blue,
	get_sentiment_color,
	parse_sage_thoughts,
	parse_thoughts,
	split_thoughts_by_sentiment
} from '../../../Util/Helper';

import { CloudRounded } from '@mui/icons-material';
import DailyWidgetCard from './DailyWidgetCard';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import sageCircle from '../../../assets/images/logo/sageCircle.svg';
import { useSelector } from 'react-redux';

export const get_key_stats = (
	date_range: 'Day' | 'Week' | 'Month',
	current_month_thoughts: Thought[],
	current_month_sage_thoughts: SageThoughts
) => {
	const thoughts = parse_thoughts(date_range, current_month_thoughts);
	const sage_thoughts = Object.keys(
		parse_sage_thoughts(date_range, current_month_sage_thoughts)
	);
	console.log(sage_thoughts, current_month_sage_thoughts[sage_thoughts[0]]);
	const sage_count = sage_thoughts.length;
	const thought_sentiment = split_thoughts_by_sentiment(thoughts);
	const harsh_thoughts = thought_sentiment.NEGATIVE;
	const harsh_count = harsh_thoughts.length;
	const joy_count = thought_sentiment.POSITIVE.length;
	const thought_icon_size = date_range === 'Week' ? '20px' : '28px';
	const sage_icon_size = date_range === 'Week' ? '24px' : '32px';
	const joy_icon_size = date_range === 'Week' ? '20px' : '30px';
	console.log(sage_count, harsh_count);
	return [
		{
			text: 'Joy',
			icon: <SentimentIcon sentiment="joy" size={joy_icon_size} />,
			color: {
				dark: get_sentiment_color('joy').color,
				light: '#FBF5D1'
			},
			value:
				joy_count + harsh_count === 0
					? '-'
					: Math.ceil((joy_count / (joy_count + harsh_count)) * 100) +
					  '%'
		},
		{
			text: 'Sage',
			icon: <SentimentIcon sentiment="sage" size={sage_icon_size} />,
			color: {
				dark: get_sentiment_color('sage').color,
				light: '#E1F0E7'
			},
			value:
				harsh_count === 0
					? '-'
					: Math.ceil((sage_count / harsh_count) * 100) + '%'
		},

		{
			text: 'Thoughts',
			icon: (
				<CloudRounded
					style={{ width: thought_icon_size, color: '#cecece' }}
				/>
				// <img
				//   src={sageCircle}
				//   style={{ height: thought_icon_size, width: thought_icon_size }}
				// />
			),
			color: {
				dark: get_sentiment_color('harsh').color,
				light: get_sentiment_color('harsh').backgroundColor
			},
			value: thoughts.length
		}
	];
};
export type WellbeingComponent = {
	text: string;
	icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
	color: string;
	value: string;
};
export const MainStats = (props: { date_range: 'Day' | 'Week' | 'Month' }) => {
	const current_month_thoughts = useSelector(
		(state: RootState) => state.thoughts.current_month_thoughts
	);
	const current_month_sage_thoughts = useSelector(
		(state: RootState) => state.sageThoughts
	);
	const key_stats = get_key_stats(
		props.date_range,
		current_month_thoughts,
		current_month_sage_thoughts
	);

	return (
		<Grid
			container
			item
			xs
			height="100%"
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			{key_stats.map((component, idx) => (
				<Grid
					container
					item
					xs
					marginTop={idx > 0 ? '8px' : 0}
					height="40px"
					// sx={{ backgroundColor: 'red' }}
				>
					{props.date_range === 'Week' ? (
						<>
							{component.icon}
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									fontFamily: 'arial'
								}}
							>
								{component.text}
							</Typography>
						</>
					) : (
						<DailyWidgetCard
							title={component.text}
							value={component.value}
							icon={component.icon}
							selected={false}
							// margin={true}
							onClick={() => {}}
						/>
					)}
				</Grid>
			))}
		</Grid>
	);
};
export default MainStats;
