import { Grid } from "@mui/material";
import { MainStats } from "../../quest/stats/daily/KeyStats";

export default function UserPulse(props: {
  date_range: "Day" | "Week" | "Month";
}) {
  return (
    <>
      <Grid
        container
        item
        // xs
        sx={{
          boxShadow: "0px 0px 4px 4px white",
          borderRadius: "16px",
        }}
        direction="column"
      >
        <Grid
          container
          item
          //   xs
          direction="column"
          sx={{
            height: "210px",
            borderRadius: "16px",
            backgroundColor: "white",
            backgroundImage:
              "linear-gradient(145deg, rgba(255, 244, 225, 0.42), rgba(217, 240, 231, 0.42), rgba(255, 227, 227, 0.42))",
          }}
          padding="16px"
        >
          {/* <Grid container item xs alignItems="center"> */}
          <MainStats date_range={props.date_range} />
          {/* </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}
