import { Grid, Typography } from "@mui/material";
import {
  SentimentIcon,
  getDarkGray,
  getDarkGreen,
  get_dark_blue,
  get_today,
  parse_daily_thoughts,
} from "../../../Util/Helper";
import {
  dash_grid_styles,
  dash_title_typography_styles,
} from "../../../Util/Styles";

import React from "react";
import { RootState } from "../../../Util/Redux/Schema";
import ThoughtBar from "./ThoughtBar";
import ThoughtList from "./ThoughtList";
import { useSelector } from "react-redux";

export const ThoughtsEditor: React.FC = () => {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const current_month_thoughts = useSelector(
    (state: RootState) => state.thoughts.current_month_thoughts
  );
  const thoughts = parse_daily_thoughts(current_month_thoughts);
  return (
    <Grid
      container
      item
      xs={8}
      sx={dash_grid_styles}
      direction="column"
      marginRight="8px"
      borderRadius="16px"
      ref={boxRef}
      padding="36px 0"
    >
      <Grid container item xs direction="column" sx={{ padding: "0 40px" }}>
        <Grid container item alignItems="center">
          <Grid container item xs>
            <Typography
              sx={dash_title_typography_styles}
              // marginBottom="-8px"
            >
              {get_today()}
            </Typography>
          </Grid>
          {/* <Grid container item xs justifyContent="flex-end">
            <Typography
              sx={{
                cursor: "default",
                fontFamily: "arial",
                fontSize: "14px",
                fontWeight: 420,
                color: getDarkGreen(),
              }}
            >
              {thoughts.length} thoughts
            </Typography>
          </Grid> */}
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          item
          xs
        >
          <ThoughtList thoughts={thoughts} date_range="Day" mutable />

          <ThoughtBar />
        </Grid>
      </Grid>
      {/* )} */}
    </Grid>
  );
};

export default ThoughtsEditor;
