import '../App.css';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
	Navigate,
	Route,
	BrowserRouter as Router,
	Routes,
	useNavigate
} from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Theme, useMediaQuery } from '@mui/material';
// import Login from './Login2';
import store, { persistor } from '../Util/Redux/Store';
import { useEffect, useState } from 'react';

import { AppDispatch } from '../Util/Redux/Schema';
import CallbackPage from '../components/pages/CallbackRoute';
import Dashboard from '../components/pages/Dashboard';
import ErrorPage from '../components/pages/ErrorPage';
import InactivityTracker from './InactivityTracker';
import LandingPage from '../components/pages/LandingPage';
import LockScreen from '../components/pages/LockScreenPage';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import SizeErrorPage from '../components/pages/SizeErrorPage';
import { ThemeProvider } from '@mui/styles';
import Waitlist from '../components/pages/WaitlistPage';
import axios from 'axios';
import { fetchUser } from '../Util/Redux/Middleware';
import { setBearerTokenToAxios } from '../Util/Redux/AsyncMiddleware';
import { setupAxiosInterceptors } from '../Util/axiosConfig';
import theme from '../Util/Theme';
import { useAuth0 } from '@auth0/auth0-react';

const AppRoutes = () => {
	// const is_mobile = false;
	const is_mobile = useMediaQuery('(max-width:1366px)');
	const dispatch = useDispatch<AppDispatch>();
	const {
		getAccessTokenSilently,
		isAuthenticated,
		user,
		isLoading,
		logout,
		loginWithRedirect
	} = useAuth0();
	if (sessionStorage.getItem('isAuthenticated') !== 'True') {
		sessionStorage.setItem(
			'isAuthenticated',
			isAuthenticated ? 'True' : 'False'
		);
	}
	useEffect(() => {
		const handleAuthenticatedUser = async () => {
			if (sessionStorage.getItem('bearerToken')) {
				setBearerTokenToAxios();
			}
			{
				try {
					if (isAuthenticated && user) {
						dispatch(fetchUser({ firstName: user.given_name }));
						sessionStorage.setItem(
							'username',
							user.given_name || ''
						);
						sessionStorage.setItem('isAuthenticated', 'True');
						sessionStorage.setItem(
							'authorized_state',
							'authorized'
						);
						const token = await getAccessTokenSilently();
						sessionStorage.setItem('bearerToken', token);
						setBearerTokenToAxios();
					}
				} catch (error) {
					console.error('Error handling authenticated user:', error);
				}
			}
		};
		handleAuthenticatedUser();
	}, [isAuthenticated, user, getAccessTokenSilently, dispatch]);

	if (isLoading && sessionStorage.getItem('username') === null) {
		return <div>Loading...</div>;
	}
	const is_valid_user =
		sessionStorage.getItem('authorized_state') !== 'user_not_found';

	return (
		<Router>
			<InactivityTracker />
			<Routes>
				<Route path="/error" element={<ErrorPage />} />
				<Route path="/auth/callback" element={<CallbackPage />} />
				{is_mobile ? (
					<>
						<Route path="/" element={<SizeErrorPage />} />
						<Route path="/waitlist" element={<SizeErrorPage />} />
						<Route
							path="/space"
							element={
								is_valid_user ? (
									<SizeErrorPage />
								) : (
									<Navigate to="/" />
								)
							}
						/>
						<Route
							path="/space/gringotts"
							element={
								is_valid_user ? (
									<SizeErrorPage />
								) : (
									<Navigate to="/waitlist" />
								)
							}
						/>
					</>
				) : (
					<>
						<Route path="/" element={<LandingPage />} />
						<Route path="/waitlist" element={<Waitlist />} />
						<Route path="/space" element={<Dashboard />} />
						<Route
							path="/space/gringotts"
							element={
								is_valid_user ? (
									<LockScreen />
								) : (
									<Navigate to="/waitlist" />
								)
							}
						/>
					</>
				)}
			</Routes>
		</Router>
	);
};

export default function App() {
	const loc = window.location;
	const baseUrl = `${loc.protocol}//${loc.hostname}${
		loc.hostname === 'localhost' ? ':8080' : ''
	}`;
	axios.defaults.baseURL = baseUrl;
	useEffect(() => {
		setupAxiosInterceptors();
	}, []);
	return (
		<HelmetProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<ThemeProvider theme={theme}>
						<Helmet>
							<script>
								{`
								(function(h,o,t,j,a,r){
									h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
									h._hjSettings={hjid:5279026,hjsv:6};
									a=o.getElementsByTagName('head')[0];
									r=o.createElement('script');r.async=1;
									r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
									a.appendChild(r);
								})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
							`}
							</script>
						</Helmet>
						<AppRoutes />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</HelmetProvider>
	);
}
