import {
  Add,
  Check,
  ChevronRight,
  Circle,
  Close,
  Remove,
} from "@mui/icons-material";
import {
  Bias,
  GuidingQuestions,
  RootState,
  SageThought,
  Thought,
} from "../../../Util/Redux/Schema";
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  COGNITIVE_BIAS_DESCRIPTOR,
  EMOTION_DESCRIPTOR,
  EMOTION_GROUP_COLOR_MAP,
  get_cta_color,
  get_emotion_group,
} from "../../../Util/Helper";

import sagethat from "../../../assets/images/logo/sagethat.png";
import { useSelector } from "react-redux";
import { useState } from "react";

export const Header = () => {
  return (
    <Grid container item justifyContent="space-between" alignItems="center">
      <img src={sagethat} style={{ height: "32px", width: "140px" }} />
      <Grid container item xs justifyContent="flex-end">
        <IconButton>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const SageSummary = (props: {
  thought: Thought;
  sageThought: SageThought;
}) => {
  const [openIdx, setOpenIdx] = useState(0);
  return (
    <>
      <Sense
        emotions={props.thought.user_selected_emotions}
        open={openIdx === 0}
        setOpen={() => setOpenIdx(0)}
      />
      <Assess
        guiding_questions={props.sageThought.guiding_questions}
        open={openIdx === 1}
        setOpen={() => setOpenIdx(1)}
      />
      <GenerateJoy
        userReframedThought={props.sageThought.user_reframed_thought}
        cosReframedThought={props.sageThought?.reframed_thought || ""}
        open={openIdx === 2}
        setOpen={() => setOpenIdx(2)}
      />
      <Embrace
        open={openIdx === 3}
        setOpen={() => setOpenIdx(3)}
        disabled={false}
        sageThought={props.sageThought}
      />
    </>
  );
};
export const SageWorksheetSummary = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  thought: Thought;
}) => {
  const sageThought = useSelector(
    (state: RootState) => state.sageThoughts[props.thought.uuid]
  );
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      sx={{ zIndex: 2000, backdropFilter: "blur(5px)" }}
    >
      <Grid
        container
        style={{
          padding: "32px",
          width: "680px",
          height: "680px",
          borderRadius: "16px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          overflowY: "auto",
        }}
        direction="column"
      >
        <Grid container item direction="column" justifyContent="center">
          {/* <img src={sagethat} style={{ width: "160px" }} /> */}
          <Header />
          <ViewThought thought={props.thought.thought} />
          <SageSummary thought={props.thought} sageThought={sageThought} />
        </Grid>
      </Grid>
    </Modal>
  );
};

const ViewThought = (props: { thought: string }) => {
  return (
    <Grid container item alignItems="center" margin="8px 0px">
      {/* <MeteoconsLightningBoltFill /> */}
      <Tooltip PopperProps={{ style: { zIndex: 5000 } }} title={props.thought}>
        <Typography
          // textAlign="center"
          sx={{
            fontSize: "14px",
            lineHeight: "20px",
            fontFamily: "arial",
            fontWeight: 600,
            color: "#7F7D83",
            cursor: "default",
            textDecoration: "underline",
          }}
        >
          {/* <MeteoconsLightningBoltFill /> */}
          View your harsh thought
        </Typography>
      </Tooltip>
    </Grid>
  );
};

const BodyTypography = (props: { children: React.ReactElement | string }) => {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "arial",
        fontWeight: 400,
        color: "#7F7D83",
        cursor: "default",
        // marginBottom: "56px",
      }}
    >
      {props.children}
    </Typography>
  );
};

const Sense = (props: {
  emotions: string[];
  open: boolean;
  setOpen: () => void;
}) => {
  return (
    <>
      <Title
        title={
          props.open
            ? `Shift from ${
                props.emotions.length > 1 ? "these emotions" : "this emotion"
              }`
            : "Shift"
        }
        open={props.open}
        completed={true}
        setOpen={props.setOpen}
      />
      {props.open && (
        <Grid
          justifyContent="center"
          alignItems="center"
          borderLeft="2px solid lightgray"
          paddingLeft="16px"
          margin="12px"
          direction="column"
        >
          {props.emotions.map((label, idx) => (
            <Tooltip
              title={EMOTION_DESCRIPTOR[label]}
              PopperProps={{
                modifiers: [
                  {
                    name: "zIndex",
                    enabled: true,
                    phase: "afterWrite",
                    fn: ({ state }) => {
                      state.styles.popper.zIndex = 20000; // Set a higher zIndex than the modal (default is 1300)
                    },
                  },
                ],
              }}
            >
              <Chip
                label={label}
                style={{
                  display: "inline-flex",
                  width: "auto",
                  marginBottom: "12px",
                  cursor: "default",
                  marginLeft: idx > 0 ? "8px" : "",
                  backgroundColor:
                    EMOTION_GROUP_COLOR_MAP[get_emotion_group(label)],
                }}
              />
            </Tooltip>
          ))}
        </Grid>
      )}
    </>
  );
};

const Assess = (props: {
  guiding_questions: GuidingQuestions[];
  open: boolean;
  setOpen: () => void;
}) => {
  const patterns = props.guiding_questions;
  return (
    <>
      <Title
        title={props.open ? "Assess your thought patterns" : "Assess"}
        open={props.open}
        completed={true}
        setOpen={props.setOpen}
      />
      {props.open && (
        <Grid
          container
          borderLeft={`2px solid lightgray`}
          paddingLeft="16px"
          margin="12px"
          direction="column"
          paddingBottom="8px"
        >
          <Grid container item>
            {patterns.map((type, idx) => (
              <Grid
                container
                direction="column"
                marginTop={idx === 0 ? "4px" : "0px"}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 450,
                    fontColor: "#2c2c2c",
                    fontFamily: "arial",
                  }}
                >
                  {type.question}
                </Typography>
                {idx + 1 !== patterns.length && (
                  <Divider
                    style={{
                      marginTop: "16px",
                      marginBottom: "16px",
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const GenerateJoy = (props: {
  userReframedThought: string;
  cosReframedThought: string;
  open: boolean;
  setOpen: () => void;
}) => {
  const [userReframedThought, setUserReframedThought] = useState(
    props.userReframedThought
  );
  // if (userReframedThought !== props.userReframedThought) {
  return (
    <>
      <Title
        title={props.open ? "Generate a reframed thought" : "Generate"}
        open={props.open}
        completed={true}
        setOpen={props.setOpen}
      />
      {props.open && (
        <Grid
          container
          borderLeft={`2px solid lightgray`}
          // height="160px"
          paddingLeft="16px"
          margin="12px"
          direction="column"
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "arial",
              color: "#858580",
              paddingBottom: "8px",
            }}
          >
            {props.userReframedThought || ""}
          </Typography>
        </Grid>
      )}
    </>
  );
};

const Title = (props: {
  title: string;
  open: boolean;
  completed: boolean;
  setOpen: () => void;
  disabled?: boolean;
}) => {
  const [hover, setHover] = useState(false);
  return (
    <Grid
      container
      onClick={props.setOpen}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      marginTop="16px"
      borderRadius="8px"
      justifyContent="center"
      alignItems="center"
      paddingLeft="4px"
      sx={{
        cursor: props.disabled ? "default" : "pointer",
        backgroundColor: hover && !props.disabled ? "#F1F1F1" : "transparent",
      }}
    >
      {props.completed ? (
        <Check
          sx={{
            marginRight: "8px",
            width: "18px",
            height: "18px",
            padding: "2px",
            backgroundImage:
              "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
            borderRadius: "50%",
            color: get_cta_color(),
          }}
        />
      ) : (
        <Circle
          sx={{
            marginRight: "8px",
            width: "18px",
            height: "18px",
            padding: "2px",
            backgroundImage:
              "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
            borderRadius: "50%",
            color: "white",
          }}
        />
      )}
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "20px",
          fontFamily: "arial",
          fontWeight: 400,
          color: "#7F7D83",
          cursor: "default",
        }}
      >
        <b style={{ color: get_cta_color(), fontSize: "20px" }}>
          {props.title[0]}
        </b>
        {props.title.slice(1, props.title.length)}
      </Typography>
      <Grid container item xs justifyContent="flex-end">
        <IconButton
          disabled={props.open || props.disabled}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ChevronRight style={{ rotate: props.open ? "-90deg" : "90deg" }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const Embrace = (props: {
  open: boolean;
  setOpen: () => void;
  disabled: boolean;
  sageThought: SageThought;
}) => {
  return (
    <>
      <Title
        title={props.open ? "Elevate your mind" : "Elevate"}
        disabled={props.disabled}
        open={props.open}
        completed={true}
        setOpen={props.setOpen}
      />
      {props.open && !props.disabled && (
        <Grid
          container
          borderLeft={`2px solid lightgray`}
          // height="160px"
          padding="0 16px"
          margin="12px"
          direction="column"
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "arial",
              color: "#858580",
              paddingBottom: "8px",
            }}
          >
            Congratulations on Saging your harsh thought!{" "}
            {props.sageThought.empathy || ""}
            <br />
            <br />
            Here are some helpful tips:
            <br />
            {props.sageThought.healthy_suggestions || ""}
          </Typography>
          {/* <Breathwork
						progressCount={progressCount}
						setProgressCount={updateProgressCount}
						maxCount={4}
						completed={props.completed}
					/> */}
        </Grid>
      )}
    </>
  );
};

const SubmitButton = (props: { onClick: () => void; disabled: boolean }) => {
  return (
    <Button
      onClick={props.onClick}
      disabled={props.disabled}
      sx={{
        "&:hover": {
          backgroundColor: get_cta_color(),
          textDecoration: undefined,
        },
        width: "276px",
        marginTop: "8px",
        textTransform: "none",
        textDecoration: undefined,

        ":disabled": {
          backgroundColor: "#E6E6E6",
        },
        backgroundColor: get_cta_color(),
        color: "white",
        cursor: "pointer",
        //   marginBottom: "48px",
      }}
    >
      Save and Close
    </Button>
  );
};

export const BreathCountSelector = () => {
  const [breaths, setBreaths] = useState(5);
  return (
    <Grid
      container
      item
      alignItems="center"
      width="276px"
      height="64px"
      marginBottom="4px"
      borderRadius="12px"
    >
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        onClick={() => setBreaths(breaths > 1 ? breaths - 1 : breaths)}
        sx={{
          width: "40px",
          height: "40px",
          border: "1px solid #F1F1F1",
          borderRadius: "12px",
          cursor: breaths > 1 ? "cursor" : "not-allowed",
        }}
      >
        <Remove
          sx={{
            width: "20px",
            height: "20px",
            color: breaths > 1 ? get_cta_color() : "#DCDCDE",
          }}
        />
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          marginLeft: "24px",
          marginRight: "24px",
          width: "148px",
          height: "48px",
          borderRadius: "12px",
          border: "1px solid #F1F1F1",
        }}
      >
        <input
          value={breaths}
          onChange={(e) => setBreaths(parseInt(e.target.value))}
          style={{
            width: "100px",
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 500,
            textAlign: "center",
            border: "none",
          }}
        ></input>
      </Grid>
      <Grid
        container
        item
        onClick={() => setBreaths(breaths + 1)}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "40px",
          height: "40px",
          border: "1px solid #F1F1F1",
          borderRadius: "12px",
          cursor: "cursor",
        }}
      >
        <Add
          sx={{
            width: "20px",
            height: "20px",
            color: get_cta_color(),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SageWorksheetSummary;
