import React, { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

const InactivityTracker = () => {
	const [lastActivityTime, setLastActivityTime] = useState(Date.now());
	const INACTIVITY_LIMIT = 30 * 60 * 1000; // 30 minutes in milliseconds
	const navigate = useNavigate();

	// Function to reset the timer on any user activity
	const resetTimer = useCallback(() => {
		setLastActivityTime(Date.now());
	}, []);

	useEffect(() => {
		// Check for inactivity every second
		const checkInactivity = setInterval(() => {
			if (Date.now() - lastActivityTime >= INACTIVITY_LIMIT) {
				navigate('/space/gringotts'); // Redirect to '/inactive' route
				clearInterval(checkInactivity); // Stop checking after redirecting
			}
		}, 1000);

		// Add event listeners for user interactions
		window.addEventListener('mousemove', resetTimer);
		window.addEventListener('keydown', resetTimer);
		window.addEventListener('click', resetTimer);

		return () => {
			clearInterval(checkInactivity);
			window.removeEventListener('mousemove', resetTimer);
			window.removeEventListener('keydown', resetTimer);
			window.removeEventListener('click', resetTimer);
		};
	}, [lastActivityTime, resetTimer, navigate]);

	return null; // No UI element, purely functional
};

export default InactivityTracker;
