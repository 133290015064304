import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RootState, Thought } from "../../Util/Redux/Schema";
import { getMonth, get_cta_color, get_dark_blue } from "../../Util/Helper";
import {
  get_thought_entries,
  postSearch,
} from "../../Util/Redux/AsyncMiddleware";

import { ArrowUpward } from "@mui/icons-material";
import Entries from "../../quest/thought/feed/ThoughtList";
import Filters from "../../quest/thought/feed/ThoughtFilters";
import Search from "../../quest/thought/feed/ThoughtSearchBar";
import { useSelector } from "react-redux";

export const Vault: React.FC = () => {
  const [descending, set_descending] = useState(false);
  const [thought_entries, set_thought_entries] = useState<Thought[]>([]);
  const [month_view, set_month_view] = useState(getMonth());
  const [sentiment_idx, set_sentiment_idx] = useState(0);
  const [monthly_thoughts, set_monthly_thoughts] = useState(
    useSelector((state: RootState) => state.thoughts.current_month_thoughts)
  );
  const on_change_descending = () => {
    set_thought_entries([...thought_entries].reverse());
    set_descending(!descending);
  };
  const on_change_sentiment_idx = () => {
    updateFilterBySentiment((sentiment_idx + 1) % 4, monthly_thoughts);
  };
  const updateFilterBySentiment = (
    sentimentIndex: number,
    entries: Thought[]
  ) => {
    if (sentimentIndex !== sentiment_idx) {
      set_sentiment_idx(sentimentIndex);
    }
    var entriesToShow = [];
    if (sentimentIndex === 0) {
      entriesToShow = entries;
    } else if (sentimentIndex === 1) {
      entriesToShow = entries.filter((obj) => {
        return (
          obj.sentiment.toLowerCase() === "positive" &&
          obj.user_selected_emotions.length > 0
        );
      });
    } else if (sentimentIndex === 2) {
      entriesToShow = entries.filter((obj) => {
        return (
          obj.sentiment.toLowerCase() === "negative" &&
          obj.user_selected_emotions.length > 0
        );
      });
    } else {
      entriesToShow = entries.filter((obj) => {
        return obj.user_selected_emotions.length === 0;
      });
    }
    setEntries(entriesToShow);
  };
  const setEntries = (thoughts: Thought[]) => {
    descending
      ? set_thought_entries(thoughts.reverse())
      : set_thought_entries(thoughts);
  };
  const fetchData = async (month: string) => {
    const entries = await get_thought_entries(month);
    if (entries === undefined) {
      set_monthly_thoughts([]);
    } else {
      set_monthly_thoughts(entries);
      updateFilterBySentiment(sentiment_idx, entries);
    }
  };
  useEffect(() => {
    if (monthly_thoughts.length === 0) {
      fetchData(month_view);
    } else {
      setEntries(monthly_thoughts);
    }
  }, []);
  const on_change_month_view = (month: string) => {
    set_month_view(month);
    fetchData(month);
  };

  const onEnterSearch = async (query: string) => {
    const searchedThoughts = await postSearch(query);
    set_thought_entries(searchedThoughts);
  };
  const SearchBar = () => (
    // <FilterRow>
    <Grid container item xs sx={{ alignItems: "center", padding: "16px" }}>
      <Search onEnterSearch={onEnterSearch} />
    </Grid>
    // </FilterRow>
  );
  const Filter = (
    <Filters
      on_change_descending={on_change_descending}
      on_change_sentiment_idx={on_change_sentiment_idx}
      sentiment_idx={sentiment_idx}
      descending={descending}
      search_bar={<SearchBar />}
    />
  );

  return (
    <Grid
      container
      item
      width="1024px"
      height="768px"
      maxHeight="100%"
      direction="column"
      justifyContent="center"
    >
      <Grid
        container
        item
        xs
        sx={{
          width: "100%",
          padding: "2px",
          borderRadius: "16px",
          // backgroundImage: "linear-gradient(145deg, #FDDFA5, #FFA6A5)",
          // border: "2.4px solid transparent",
          border: "2.4px solid #CEE7F7",
          backgroundClip: "padding-box",
        }}
        direction="column"
      >
        <Grid
          container
          item
          xs
          sx={{ backgroundColor: "white", borderRadius: "12px" }}
          direction="column"
        >
          <Grid
            container
            item
            paddingTop="8px"
            sx={{
              // margin: "-2px",
              backgroundColor: "#d5e7f2",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {/* <SearchBar /> */}
            {Filter}
          </Grid>

          <Divider />
          <Entries
            // filters={Filter}
            thoughts={thought_entries}
            mutable={false}
            date_range="Month"
          />
        </Grid>
        {/* <Grid
          container
          item
          xs
          position="absolute"
          // top={-24}
          right="32px"
          top="102px"
          width="32px"
          height="32px"
        >
          <ArrowUpward style={{ color: get_cta_color() }} />
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Vault;
