import { Grid, TextareaAutosize } from "@mui/material";
import React, { useEffect, useRef } from "react";

const CustomTextField = (props: {
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  setCompleted?: (completed: boolean) => void;
  backgroundColor?: string;
  disabled?: boolean;
}) => {
  const { placeholder, value, setValue } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (props.setCompleted) {
      if (event.target.value.length === 1) {
        props.setCompleted(true);
      } else if (event.target.value.length === 0) {
        props.setCompleted(false);
      }
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default Enter behavior (line break)
    } else if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault(); // Prevent the default Enter behavior (line break)
      setValue(value + "\n"); // Add a newline character to the value
    }
  };
  const textareaRef = useRef();

  useEffect(() => {
    // Ensure the textarea scrolls to the bottom when new content is added
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [value]);

  return (
    <Grid
      container
      item
      xs
      justifyContent="space-between"
      width="100%"
      // height="100%"
      alignItems="center"
      sx={{
        backgroundColor: props.backgroundColor || "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <Grid container item xs height="100%">
        <TextareaAutosize
          disabled={props.disabled === true}
          placeholder={placeholder}
          ref={textareaRef}
          value={value}
          maxRows={2}
          style={{
            fontFamily: "arial",
            color: "#0A090B",
            fontWeight: 450,
            backgroundColor: props.backgroundColor || "#EBE9F8",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "-0.05px",
            width: "100%",
            border: "none",
            outline: "none",
            resize: "none",
            height: "100%",
          }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </Grid>
    </Grid>
  );
};

export default CustomTextField;
