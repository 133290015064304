import axios, { AxiosError, AxiosResponse } from "axios";
const instance = axios.create();

export const setupAxiosInterceptors = () => {
  // Set up the response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Successful response, return as-is
      return response;
    },
    (error: AxiosError) => {
      // Log the error to confirm if it's reaching the interceptor
      console.error("Interceptor caught error:", error);

      if (error.response && error.response.status >= 500) {
        // Redirect to the error page on 500 errors
        window.location.href = "/error";
      } else if (error.response && error.response.status === 401) {
        window.location.href = "/";
      } else if (error.response && error.response.status === 409) {
        window.location.href = "/waitlist";
      }

      // Reject the error to pass it down the chain
      return Promise.reject(error);
    }
  );
};

export default instance;
