import { useEffect, useRef } from "react";

import { ArcherContainer } from "react-archer";
import { DateRange } from "../../../Util/Helper";
import { Grid } from "@mui/material";
import { Thought } from "../../../Util/Redux/Schema";
import ThoughtEntry from "./ThoughtEntry";
import ThoughtsEmptyState from "./ThoughtEmptyState";

const ThoughtList = ({
  thoughts,
  mutable,
  date_range,
  filters,
}: {
  thoughts: Thought[];
  mutable: boolean;
  date_range?: DateRange;
  filters?: any;
}) => {
  const scroll_ref = useRef(null);
  useEffect(() => {
    if (scroll_ref.current) {
      scroll_ref.current.scrollTop = scroll_ref.current.scrollHeight;
    }
  }, [thoughts]);

  return (
    <Grid
      container
      item
      xs
      direction="column"
      alignItems="flex-start"
      sx={{
        width: "100%",
        padding: "16px 0",
        paddingBottom: "32px",
      }}
    >
      {filters}
      <Grid
        container
        item
        xs
        direction="column"
        ref={scroll_ref}
        sx={{
          margin: "8px 0",
          overflowY: "auto",
          flexWrap: "nowrap",
          "&::-webkit-scrollbar": {
            display: "none",
            //   width: "10px", // Customize scrollbar width
          },
        }}
      >
        {thoughts.length === 0 ? (
          <ThoughtsEmptyState date_range={date_range} />
        ) : (
          [...thoughts].map((thought, idx) => (
            <ArcherContainer style={{ width: "100%" }} endMarker={false}>
              <Grid container item xs key={thought.uuid}>
                <ThoughtEntry thought={thought} mutable={mutable} />
              </Grid>
            </ArcherContainer>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default ThoughtList;
