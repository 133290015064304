import {
	Add,
	Remove,
	StarRounded,
	TipsAndUpdatesRounded
} from '@mui/icons-material';
import {
	AppDispatch,
	Bias,
	GuidingQuestions,
	RootState,
	SageThought,
	Thought
} from '../../../Util/Redux/Schema';
import {
	Button,
	Chip,
	Grid,
	Modal,
	Skeleton,
	Tooltip,
	Typography
} from '@mui/material';
import {
	COGNITIVE_BIAS_DESCRIPTOR,
	getDarkGray,
	get_cta_color,
	get_dark_blue,
	get_sage_thought,
	get_sentiment_color
} from '../../../Util/Helper';
import { useDispatch, useSelector } from 'react-redux';

import Breathwork from '../../mindfulness/BreathCircle';
import CustomTextField from '../../../components/molecules/CustomTextField';
import bottom_left_astro from '../../../assets/images/svg-files/bottom_left_astro.svg';
import sageCircle from '../../../assets/images/logo/sageCircle.svg';
import sagethat from '../../../assets/images/logo/sagethat.png';
import { update_user_reframed_thought } from '../../../Util/Redux/AsyncMiddleware';
import { useState } from 'react';

export const Header = (props: { pageIdx: number }) => {
	return (
		<Grid container item justifyContent="space-between" alignItems="center">
			<img
				src={sageCircle}
				style={{ height: '48px', width: '44px', marginRight: '8px' }}
			/>
			<img src={sagethat} style={{ height: '32px', width: '140px' }} />
			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				paddingRight="24px"
			/>
		</Grid>
	);
};
export const SageWorksheet = (props: {
	open: boolean;
	setOpen: (value: boolean) => void;
	thought: Thought;
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const [pageIdx, setPageIdx] = useState(0);

	const sage_thought = useSelector((state: RootState) =>
		get_sage_thought(state.sageThoughts, props.thought.uuid)
	);
	const [reframed_thought, setReframedThought] = useState(
		sage_thought?.user_reframed_thought || ''
	);

	const onClick = () => {
		setPageIdx(pageIdx + 1);
	};
	const [openIdx, setOpenIdx] = useState(0);
	const [completed, setCompleted] = useState<boolean[]>([
		false,
		false,
		false,
		false
	]);
	const setGenerateJoyCompleted = (isCompleted: boolean) => {
		setCompleted(
			completed.map((c: boolean, idx: number) =>
				idx === 2 ? isCompleted : c
			)
		);
	};
	const setOpenIdxAndCompleted = (idx: number) => {
		setOpenIdx(idx);
		if (idx !== 0 && !completed[0]) {
			setCompleted(completed.map((c, idx) => (idx === 0 ? true : c)));
		}
		if (openIdx === 1 && idx !== 1 && !completed[1]) {
			setCompleted(completed.map((c, idx) => (idx === 1 ? true : c)));
		}
	};
	const onSubmitWorksheet = () => {
		props.setOpen(false);
		dispatch(
			update_user_reframed_thought(
				reframed_thought,
				sage_thought.uuid,
				props.thought.uuid
			)
		);
	};
	return (
		<Modal
			open={props.open}
			onClose={() => props.setOpen(false)}
			sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
		>
			<Grid
				container
				sx={{
					padding: '32px',
					width: '680px',
					height: '680px',
					borderRadius: '16px',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor: 'white',
					overflowY: 'auto',
					'&::-webkit-scrollbar': {
						display: 'none'
					}
				}}
				direction="column"
			>
				<Grid
					container
					item
					xs
					direction="column"
					justifyContent="center"
				>
					<Header pageIdx={pageIdx} />

					{pageIdx === 0 && (
						<ViewThought thought={props.thought.thought} />
					)}
					<Grid
						container
						item
						xs
						padding="16px"
						paddingLeft={0}
						borderRadius="12px"
						justifyContent="center"
					>
						{pageIdx === 0 ? (
							<Grid container item direction="column">
								<Assess
									guiding_questions={
										sage_thought?.guiding_questions?.slice(
											0,
											2
										) || []
									}
									open={true}
									setOpen={() => setOpenIdxAndCompleted(1)}
									completed={completed[1]}
								/>
								<GenerateJoy
									userReframedThought={reframed_thought}
									setUserReframedThought={setReframedThought}
									cosReframedThought={
										sage_thought?.reframed_thought || ''
									}
									completed={completed[2]}
									setCompleted={setGenerateJoyCompleted}
									thoughtUuid={props.thought.uuid}
								/>
							</Grid>
						) : (
							<Embrace
								sageThought={sage_thought}
								onClose={onSubmitWorksheet}
							/>
						)}

						<Grid
							container
							item
							justifyContent="center"
							marginTop="24px"
							xs
						>
							{pageIdx === 0 && (
								<SubmitButton
									onClick={onClick}
									disabled={!reframed_thought}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	);
};

const ViewThought = (props: { thought: string }) => {
	return (
		<Grid container item alignItems="center" margin="8px 0px">
			<Tooltip
				PopperProps={{ style: { zIndex: 5000 } }}
				title={props.thought}
			>
				<Typography
					sx={{
						fontSize: '14px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 600,
						color: '#7F7D83',
						cursor: 'default',
						textDecoration: 'underline'
					}}
				>
					View your harsh thought
				</Typography>
			</Tooltip>
		</Grid>
	);
};

const BodyTypography = (props: { children: React.ReactElement | string }) => {
	return (
		<Typography
			sx={{
				fontSize: '14px',
				lineHeight: '20px',
				fontFamily: 'arial',
				fontWeight: 400,
				color: '#7F7D83',
				cursor: 'default'
			}}
		>
			{props.children}
		</Typography>
	);
};

const Assess = (props: {
	guiding_questions: GuidingQuestions[];
	open: boolean;
	setOpen: () => void;
	completed: boolean;
}) => {
	const patterns = props.guiding_questions;
	const EmptySkeleton = () => (
		<>
			<Skeleton animation="wave" />
			<Skeleton animation="wave" style={{ marginTop: '16px' }} />
		</>
	);
	const AssessBody = () => (
		<Grid container direction="column">
			{patterns.map((type, idx) => {
				return (
					<Grid
						container
						item
						direction="row"
						alignItems="center"
						marginTop={idx && '16px'}
					>
						<Grid container item xs>
							<Typography
								sx={{
									fontSize: '14px',
									fontWeight: 450,
									fontColor: get_dark_blue(),
									fontFamily: 'arial'
									//   marginTop: "8px",
								}}
							>
								💭 {type.question}
							</Typography>
						</Grid>
					</Grid>
				);
			})}
		</Grid>
	);
	const body = (
		<Grid
			container
			item
			xs
			marginTop="12px"
			direction="column"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '100px',
				border: '1px solid #EBEBEB'
			}}
			borderRadius="8px"
			padding="16px"
		>
			{props.guiding_questions.length > 0 ? (
				<AssessBody />
			) : (
				<EmptySkeleton />
			)}
		</Grid>
	);
	return <Template title="Assess your thought patterns" body={body} />;
};

const Template = (props: { startIcon?: any; title: string; body: any }) => {
	return (
		<>
			<Grid container item xs width="100%" alignItems="center">
				{props.startIcon}
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 600,
						// color: "#7F7D83",
						color: get_cta_color(),
						cursor: 'default'
					}}
				>
					{props.title}
				</Typography>
			</Grid>
			{props.body}
		</>
	);
};

const GenerateJoy = (props: {
	userReframedThought: string;
	setUserReframedThought: (userReframedThought: string) => void;
	cosReframedThought: string;
	completed: boolean;
	setCompleted: (completed: boolean) => void;
	thoughtUuid: string;
}) => {
	const EmptySkeleton = () => (
		<>
			<Grid container item xs width="100%" alignItems="center">
				<Grid container item xs marginBottom="24px">
					<Typography
						sx={{
							fontSize: '14px',
							lineHeight: '20px',
							fontFamily: 'arial',
							fontWeight: 400,
							color: '#7F7D83',
							cursor: 'default'
						}}
					>
						Example:{' '}
					</Typography>
					<Skeleton
						animation="wave"
						style={{ width: '496px', marginLeft: '8px' }}
					/>
					<Skeleton animation="wave" style={{ width: '564px' }} />
				</Grid>
			</Grid>
			<CustomTextField
				disabled
				placeholder="A more positive way to think about this is..."
				// value={props.userReframedThought}
				// setValue={props.setUserReframedThought}
				// setCompleted={props.setCompleted}
				backgroundColor="white"
			/>
		</>
	);
	const body = (
		<Grid
			container
			item
			// xs
			marginTop="8px"
			direction="column"
			paddingBottom="8px"
			sx={{
				backgroundColor: '#F2F5F9',
				height: '210px',
				border: '1px solid #EBEBEB'
			}}
			borderRadius="8px"
			padding="16px"
		>
			{props.cosReframedThought ? (
				<>
					<Grid container item xs width="100%" alignItems="center">
						<Grid container item xs marginBottom="24px">
							<Typography
								sx={{
									fontSize: '14px',
									lineHeight: '20px',
									fontFamily: 'arial',
									fontWeight: 400,
									color: '#7F7D83',
									cursor: 'default'
									// marginBottom: "24px",
								}}
							>
								Example:{' '}
								{props.cosReframedThought ||
									"I have my own unique preferences, and it's okay if they differ from others."}
							</Typography>
						</Grid>
					</Grid>
					<CustomTextField
						placeholder="A more positive way to think about this is..."
						value={props.userReframedThought}
						setValue={props.setUserReframedThought}
						setCompleted={props.setCompleted}
						backgroundColor="white"
					/>
				</>
			) : (
				<EmptySkeleton />
			)}
		</Grid>
	);
	return (
		<Grid container marginTop="24px">
			<Template title="Reframe your thought" body={body} />
		</Grid>
	);
};

const Embrace = (props: { sageThought: SageThought; onClose: () => void }) => {
	const text = [
		props.sageThought.healthy_suggestions,
		props.sageThought.empathy
	];
	// const
	const body = (
		<Grid
			container
			item
			direction="column"
			alignItems="center"
			justifyContent="center"
		>
			<Grid
				container
				item
				xs
				sx={{
					backgroundColor: get_cta_color(0.3),
					borderRadius: '8px',
					padding: '8px'
				}}
			>
				<Grid container item xs alignItems="center">
					<Grid container item xs={1} alignItems="center">
						<img
							src={bottom_left_astro}
							style={{ marginRight: '4px', height: '42px' }}
							alt="astro"
						/>
					</Grid>
					<Grid container item xs alignItems="center">
						<Typography
							sx={{
								fontSize: '14px',
								fontFamily: 'arial',
								color: get_dark_blue(),
								// lineHeight: '24px',
								paddingBottom: '8px',
								marginLeft: '4px'
							}}
						>
							{/* <br /> */}
							{props.sageThought.empathy}{' '}
							{props.sageThought.healthy_suggestions}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs
				marginTop="24px"
				marginBottom="-24px"
				direction="column"
				justifyContent="center"
				paddingTop="8px"
			>
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 600,
						color: '#858580',
						cursor: 'default',
						textAlign: 'center'
					}}
				>
					Take three mindful breaths, and settle down into the moment.
				</Typography>
				<Breathwork maxCount={3} size="large" onClose={props.onClose} />
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '20px',
						fontFamily: 'arial',
						fontWeight: 500,
						color: '#858580',
						cursor: 'pointer',
						textAlign: 'center',
						textDecoration: 'underline',
						marginBottom: '8px'
					}}
					onClick={props.onClose}
				>
					Skip
				</Typography>
			</Grid>
		</Grid>
	);
	return <Template title="" body={body} />;
};

const SubmitButton = (props: {
	onClick: () => void;
	disabled: boolean;
	cancel?: boolean;
}) => {
	return (
		<Button
			onClick={props.onClick}
			disabled={props.disabled}
			sx={{
				'&:hover': {
					backgroundColor: props.cancel ? 'white' : get_cta_color(),
					textDecoration: props.cancel ? 'underline' : undefined
				},
				width: '276px',
				height: '42px',
				marginTop: '8px',
				textTransform: 'none',
				textDecoration: props.cancel ? 'underline' : undefined,

				':disabled': {
					backgroundColor: props.cancel ? 'white' : '#E6E6E6'
				},
				backgroundColor: props.cancel ? 'white' : get_cta_color(),
				color: props.cancel ? '#2f2f2f' : 'white',
				cursor: 'pointer'
				//   marginBottom: "48px",
			}}
		>
			{props.cancel ? 'Skip' : 'Submit'}
		</Button>
	);
};

export const BreathCountSelector = () => {
	const [breaths, setBreaths] = useState(5);
	return (
		<Grid
			container
			item
			alignItems="center"
			width="276px"
			height="64px"
			marginBottom="4px"
			borderRadius="12px"
		>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				onClick={() => setBreaths(breaths > 1 ? breaths - 1 : breaths)}
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: breaths > 1 ? 'cursor' : 'not-allowed'
				}}
			>
				<Remove
					sx={{
						width: '20px',
						height: '20px',
						color: breaths > 1 ? get_cta_color() : '#DCDCDE'
					}}
				/>
			</Grid>
			<Grid
				container
				item
				justifyContent="center"
				alignItems="center"
				sx={{
					marginLeft: '24px',
					marginRight: '24px',
					width: '148px',
					height: '48px',
					borderRadius: '12px',
					border: '1px solid #F1F1F1'
				}}
			>
				<input
					value={breaths}
					onChange={(e) => setBreaths(parseInt(e.target.value))}
					style={{
						width: '100px',
						fontSize: '24px',
						lineHeight: '28px',
						fontWeight: 500,
						textAlign: 'center',
						border: 'none'
					}}
				></input>
			</Grid>
			<Grid
				container
				item
				onClick={() => setBreaths(breaths + 1)}
				justifyContent="center"
				alignItems="center"
				sx={{
					width: '40px',
					height: '40px',
					border: '1px solid #F1F1F1',
					borderRadius: '12px',
					cursor: 'cursor'
				}}
			>
				<Add
					sx={{
						width: '20px',
						height: '20px',
						color: get_cta_color()
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default SageWorksheet;
